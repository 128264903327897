.Input-File {
    display: flex;
    align-items: center;
    input {
        flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: transparent;

        &:disabled,
        &:read-only {
            opacity: 0.6;
            border-bottom: 1px solid gray;
        }
    }
    &-Controls {
        background-color: lightgray;
        height: 35px;
        padding: 5px;
        display: flex;
        align-items: center;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border: 1px solid #333;
        border-left: transparent;
        .Button {
            font-size: 16px;
            padding: 0;
            width: 30px;
            min-height: unset;
            height: 30px;
            &:first-child {
                margin-right: 2px;
            }
        }
    }
}

.S3-Itmes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.S3-Item {
    width: 150px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    margin: 10px;
    @media (max-width: 767px) {
        width: 100%;
    }
    img {
        max-width: 150px;
        max-height: 150px;  
    }
}

.preferred-format {
    color: gray;
    padding-top: 2px;
    font-style: italic;
    font-size: 12px;
}

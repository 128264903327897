@import '../../styles/variables.scss';

.Date-Picker {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    color: #262626;
    font-size: 16px;
    position: relative;
    @media (max-width: 767px) {
        margin-bottom: 5px;
        font-size: 14px;
    }
    label {
        font-weight: bold;
        font-size: 14px;
    }
    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__close-icon::after {
            background-color: lightgray !important;
            color: #333;
            width: 20px;
            height: 20px;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .react-datepicker {
        border-radius: 5px;
        .react-datepicker__navigation--next {
            border-left-color: white;
        }
        .react-datepicker__navigation--previous {
            border-right-color: white;
        }
        .react-datepicker__month-container {
            .react-datepicker__header {
                background-color: $primary;
                .react-datepicker__current-month,
                .react-datepicker__day-name {
                    color: white !important;
                    letter-spacing: 1px;
                }
                .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
                    span {
                        color: white;
                    }
                    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
                        border-top-color: white;
                    }
                }
            }
            .react-datepicker__month {
                .react-datepicker__day--keyboard-selected,
                .react-datepicker__day--selected {
                    background-color: $primary !important;
                }
            }
        }
        .react-datepicker__time-container {
            .react-datepicker__header {
                background-color: $primary;
                .react-datepicker-time__header {
                    color: white;
                    letter-spacing: 1px;
                }
            }
            .react-datepicker__time-list-item--selected {
                background-color: $primary !important;
            }
        }
    }

    input {
        background-color: transparent;
        border: 1px solid gray;
        color: #262626;
        border-radius: 3px;
        width: 100%;
        height: 35px;
        padding: 0 10px;
        font-size: 16px;
        letter-spacing: 1px;
        transition: all 0.4s ease-in-out;
        appearance: none;
        &:disabled {
            opacity: 0.6;
            border-color: transparent;
            border-bottom: 1px solid gray;
        }
    }
    .error-msg {
        height: 10px;
        font-size: 12px;
        color: #e73c37;
        font-weight: bold;
        margin: 3px 0 0;
        p {
          margin: 0;
        }
      }
}

.Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 60px;
    background-color: #312c38;
    box-shadow: 1px 1px 5px lightgrey;
    display: flex;
    align-items: center;
    padding: 0 15px;
    color: white;
    font-size: 24px;
    &-Main {
        flex: 1;
        display: flex;
        align-items: center;
        .toggle-btn {
            margin-right: 25px;
            background-color: transparent;
            border: none;
            color: white;
            font-size: 25px;
            padding: 0;
            display: flex;
            align-items: center;
            &:active {
                transform: scale(0.9);
            }
            @media (max-width: 767px) {
                font-size: 20px;
            }
        }
        img {
            height: 45px;
            margin-right: 10px;
            @media (max-width: 767px) {
                height: 35px;
            }
        }
        p {
            margin: 0;
            @media (max-width: 767px) {
               display: none;
            }
        }
    }
    &-Right {
        display: flex;
        align-items: center;
        .logout {
            font-size: 25px;
            margin-left: 10px;
            cursor: pointer;
            @media (max-width: 767px) {
                font-size: 20px;
            }
        }
    }
}
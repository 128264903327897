.Pagination {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: gray;
        font-weight: bold;
        &:disabled {
            opacity: 0.7;
        }
        &.selected {
            color: #171717;
            opacity: 1;
        }
    }
}
.Item {
    position: relative;
    background-color: transparent;
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header, &-Breadcrumb {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
        &-Controls {
            //flex: 1;
            display: flex;
            justify-content: flex-end;
        }
        .Button {
            margin-left: 10px;
        }
    }
    &-Breadcrumb{
        border: none;
        padding: 0
    }
    &-Content {
        padding: 5px 15px 30px 15px;
        @media (max-width: 767px) {
            padding: 10px 0;
        }
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .id {
                line-height: 25px;
                width: 100%;
                margin: 10px 0 30px 0;
                text-transform: uppercase;
                opacity: .6;
            }
            .Input, .Date-Picker, .Checkbox, .Select, .Time-Picker, .Textarea {
                width: 49%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            .Checkbox {
                min-height: 60px;
                @media (max-width: 767px) {
                    min-height: unset;
                }
            }
        }
        &-Image {
            width: 100%;
            min-height: 100px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100px;
                margin: 0 25px;
                @media (max-width: 767px) {
                    width: 80px;
                }
            }
            
        }
    }
    &.no-navigation {
        width: 100%;
        .Item-Header {
            min-height: unset;
            border: none;
        }
        .Item-Content {
            padding: 15px;
        }
    }
}
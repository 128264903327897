.Page {
    width: 100%;
    max-width: 1300px;
    margin: 100px 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    @media (max-width: 767px) {
        padding: 60px 5px;
    }
    &-Loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Select {
    &.wider {
        width: 315px;
    }

    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    color: #262626;
    font-size: 16px;
    position: relative;
    @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
    label {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }
    .error-msg {
        height: 10px;
        font-size: 12px;
        color: #e73c37;
        font-weight: bold;
        margin: 3px 0 0;
        p {
            margin: 0;
        }
    }
}

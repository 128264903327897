@import '../../styles/variables.scss';

.Button {
    background-color: $primary;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    min-height: 40px;
    letter-spacing: 1px;
    overflow: hidden;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: $primary_disabled;
    }
    }
    &:hover {
        background-color: $primary_hover;
    }
    &.fullwidth {
        width: 100%;
    }
    &.marginRight {
        margin-right: 10px;
    }
    &.dark {
      background-color: #171717;
    }
    &.light {
      color: black;
      background-color: lightgray;
    }
    &.loader {
      & > span {
        opacity: 0;
      }
    }
    svg {
        min-width: 25px;
    }
    @media (max-width: 767px) {
        font-size: 14px;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.Fan-Games {
    position: relative;
    background-color: transparent;
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        margin: 0;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
    .game {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        cursor: pointer;
        background-color: #f4f6f7;
        border-bottom: 1px solid #dcddde;
        height: 60px;
        &:nth-child(even) {
            background-color: transparent;
        }
        &:hover {
            background-color: #fbe9ea;
        }
    }
}

.lineup-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    .team-logos{
        display: flex;
        width: 40%;
        margin: auto;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px 0;
        div{
            width: 70px;
        }
      
        
    }
    .line-title{
        font-weight: normal;
    }
    div{
        text-align: center;
        justify-content: center;
        width: 80%;
    }
    .player-wrapper {
      display: flex;
      padding-top: 0;
      flex-direction: row;
    }
    
    .position {
        display: flex;
        flex: 1;
        height: 18px;
        span{
           position: absolute;
        }
    }
    .faceoff-wrapper{
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .left-player, .right-player{
            padding: 0;
            width: 30%;
            min-width: 240px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 0;
            span{
                margin: 5px;
            }
            .id-span{
                white-space: nowrap;
                font-size: 14px;
            }
            .name{
                flex: 1;
                text-align: start;
                font-size: 14px;
            }
            div{
                margin: 0;
                padding: 5px;
                width: 55px;
            }
        }
       .right-player{
        display: flex;
        flex-direction: flex-end;
       }

        .spec-span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .left-team, .right-team {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0;
        padding-top: 0;
        width: 30%;
        min-width: 240px;
        div{
            margin: 0;
        }
        div:first-child{
            padding-top: 0;
        }
        .left-player, .right-player{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
                margin: 5px;
            }
            .id-span{
                white-space: nowrap;
                font-size: 14px;
            }
            .name{
                flex: 1;
                text-align: start;
                font-size: 14px;
            }
            div{
                padding: 5px;
            }
        }
       
    }
    .line-title {
        text-align: center;
        line-height: 1.5em;
        width: 90%;
        display: flex;
        .line {
            display: flex;
            border-bottom: 1px solid black;
            flex:1;    
            margin: 18px 10px; 

        }
    
    }
    
}
@import 'variables.scss';

* {
    box-sizing: border-box;
    font-family: 'Arial';
}

html,
body {
    margin: 0;
    padding: 0;
}

main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #e0e0e0;
}

button {
    cursor: pointer;
    &:disabled {
        cursor: unset;
    }
    &:focus {
        outline: none;
    }
}

input,
select,
textarea {
    &:focus {
        outline: none;
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

@font-face {
    font-family: 'Roboto';
    src: url('./../assets/fonts/Roboto-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SansaSlab-Black';
    src: url('./../assets/fonts/SansaSlab-Black.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./../assets/fonts/HelveticaNeue.ttc');
    font-weight: normal;
    font-style: normal;
}

.confirm {
    color: green;
}

.error {
    color: red;
}

.error_boundaries{
    h2{
        color: black;
    }
    p{
        padding: 0 0 20px 0;
        margin: 0;
        font-size: 14px;
        color: red;
        letter-spacing: 1px;
    }
   
}
.league-select-wrapper,
.tab-controls {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2.5%;
    .Select {
        min-width: 217px;
        margin-right: 30px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .title {
        width: 100%;
        font-weight: normal;
    }
    .feedback {
        color: green;
        margin: 0;
        font-style: italic;
        width: 100%;
        span {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
.league-select-wrapper{
    margin-left: 0;
}
.season-label, .season-label_two-lines, .season-label_one_line {
    height: 38px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.season-label_one_line{
    display: flex;
    align-items: center;
    span{
        margin-right: 15px;
    }
}

.season-label_two-lines{
    height: 16px;
    display: flex;
    align-items: center;
   
}


.css-n1bees-control {
    div {
        color: #262626 !important;
    }
}

.season-pass-holders-checkbox {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2.5%;
    margin-top: -50px;
    padding: 5px 15px 30px 15px;
}

.shop_item-header {
    width: 100%;
    min-height: 50px;
    font-size: 24px;
    padding: 0 15px;
    border-bottom: 1px solid #dcddde;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 0;
        font-size: 18px;
    }
}

.description_row {
    p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0;
    }
}

.link-label{
    color: blue;
    cursor: pointer;
    font-size: 14px;
    &:hover{
        text-decoration: underline;
    }
}
.link_text{
    color: blue;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.no-data{
    letter-spacing: 1px;
    font-family:sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
    font-style: italic;
}

@import '../../styles/variables.scss';

.Table {
    position: relative;
    background-color: transparent;
    border-radius: 5px;
    margin: 15px 0;
    width: 100%;
    color: #333;
    @media (max-width: 767px) {
        margin: 5px auto;
        font-size: 14px;
    }
    &-Name,&-Woman_League-Name {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        p,
        td {
            margin: 0;
            width: 100%;
        }
        @media (max-width: 767px) {
            height: unset;
            line-height: unset;
            padding: 5px;
            font-size: 18px;
        }
    }
    &-Woman_League-Name {
        border: none;
    }
    &-Table {
        width: 100%;
    }
    &-Header {
      
        min-height: 60px;
        border-bottom: 1px solid #dcddde;
        background-color: #fff;
        position: sticky;
        top: 60px; 
        height: 70px;
        
        .Header-Container{
            display: flex;
            height: 70px;
            border-bottom: 1px solid #dcddde;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;
            font-weight: bold;
            font-size: 14px;
            @media (max-width: 1300px) {
                font-size: 12px;
            }
        }
        &-Cell, &-Cell_B {
            flex: 1;
            text-align: center;
            padding: 5px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                max-width: 60px;
                @media (max-width: 768px) {
                    max-width: 30px;
                }
            }
            &:nth-child(n + 4) {
                @media (max-width: 768px) {
                    display: none;
                }
            }
            &:last-child {
                @media (max-width: 768px) {
                    display: unset;
                }
            }
        }
        &-Cell_B{
            &:first-child {
                max-width: 100%;
                @media (max-width: 768px) {
                    max-width: 30px;
                }
            }
        }
    }
    &-Body {
        &-Row {
            display: flex;
            min-height: 60px;
            align-items: center;
            background-color: #f4f6f7;
            border-bottom: 1px solid #dcddde;
            @media (max-width: 1300px) {
                height: 20px;
            }
            &:nth-child(even) {
                background-color: transparent;
            }
            &.clickable {
                cursor: pointer;
            }
            &:hover {
                background-color: $primary_light;
            }
            &-Cell,&-Cell_B {
                flex: 1;
                min-height: 60px;
                height: 100%;
                width: 38px;
                font-size: 14px;
                text-overflow: ellipsis;
                
                &:first-child {
                    max-width: 60px;
                    @media (max-width: 768px) {
                        max-width: 30px;
                    }
                }
                &:nth-child(n + 4) {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                &:last-child {
                    @media (max-width: 768px) {
                        display: unset;
                    }
                    .Button {
                        margin-left: 5px;
                        @media (max-width: 768px) {
                            font-size: 12px;
                            padding: 7px;
                            min-height: unset;
                        }
                    }
                }
                div {
                    min-height: 60px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 3px;
                    @media (max-width: 1300px) {
                        font-size: 11px;
                        height: 12px;
                    }
                    &.scrollable {
                        flex-direction: column;
                        max-height: 80px;
                        padding: 10px 0;
                        overflow-y: scroll;
                    }
                    .truncate-text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    img {
                        max-height: 50px;
                        @media (max-width: 767px) {
                            height: 35px;
                        }
                    }
                    a {
                        color: blue;
                        display: flex;
                        align-items: center;
                        &:hover {
                            transform: scale(1.05);
                        }
                        svg {
                            min-width: 20px;
                            margin-right: 5px;
                        }
                    }
                    .text {
                        max-height: 80px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            &-Cell_B{
                &:first-child {
                    max-width: 100%;
                }
            }
        }
    }
}

.golden-skin {
    border: 3px solid $primary;
    border-radius: 8px;
    background-color: $primary_faded !important;
}

.gray-out {
    background-color: rgba(255, 255, 255, 0.8);
    div,
    span,
    svg {
        color: rgb(211, 210, 210);
    }

    .button-cell {
        svg {
            color: white;
        }
    }
}

.hide-cell{
    display: none;
}

.team-cell, .team-cell_wider{
    min-width: 120px ;
   
    @media (max-width: 768px) {
        max-width: 30px;
    }
}
.team-cell_wider{
    min-width: 160px;
    @media (max-width: 1300px) {
        min-width: 80px;
    }
}

.clickable{
    cursor: pointer;
    &:hover{
        color: #927c7c;
    }
}
.Drawer {
    &-Content {
        background-color: #e7e7e7;
        box-shadow: 1px 3px 5px lightgrey;
        position: fixed;
        bottom: 0;
        top: 60px;
        left: 0;
        width: 260px;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
        z-index: 101;
        padding-bottom: 100px;
        -ms-overflow-style: none; /* IE and Edge - hide scrollbar */
        scrollbar-width: none; /* Firefox - hide scrollbar */
        &::-webkit-scrollbar {
            display: none;
        }
        &.close {
            width: 55px;
        }
        .label {
            height: 20px;
            width: 250px;
            padding: 0 15px;
            margin-top: 5px;
            text-transform: uppercase;
            font-weight: bold;
            &.hide {
                span {
                    display: none;
                }
            }
        }
        .Link-Item {
            width: 260px;
            display: flex;
            align-items: center;
            padding: 5px 15px;
            color: #171717;
            letter-spacing: 1px;
            height: 40px;
            &:hover {
                background-color: #dcddde;
            }
            &.selected {
                background-color: #00529b;
                color: white;
            }
            .icon {
                width: 25px;
                height: 25px;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    font-size: 20px;
                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }
            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &-Spacer {
        width: 250px;
        height: 100%;
        transition: all 0.3s ease-in-out;
        @media (max-width: 991px) {
            width: 60px;
        }
        &.hide {
            width: 60px;
        }
    }
}

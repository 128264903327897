.ErrorMessages {
    padding: 10px 0;

    & > p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: red;
      letter-spacing: 1px;
    }
}
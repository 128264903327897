.Login {
    min-height: 100vh;
    width: 100%;
    background-color: #e7e7e8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    &-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 450px;
        padding: 50px 15px;
        background-color: white;
        border-radius: 3px;
        h2 {
            text-align: center;
            @media (max-width: 767px) {
                font-size: 22px;
            }
        }
        .logo {
            width: 150px;
            @media (max-width: 767px) {
                width: 100px;
            }
            img{
                width: 100%;
            }
        }
        form {
            width: 100%;
        }
    }

}
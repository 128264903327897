@import '../../styles/variables.scss';

.Breadcrumb {
    display: flex;
    align-items: center;
    flex: 1;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
    span {
        margin-left: 5px;
        &:first-child { 
            margin-left: 0;
        }
        a {
            &:hover {
                color: $primary;
            }
        }
    }
    .current {
        margin-left: 5px;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            margin-left: 0;
        }
    }
}
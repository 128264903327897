.filter-wrapper{
    width: 100%;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    .filter-box{
        width: 280px;
        margin-right: 20px; 
       
    }
}
@import '../../styles/variables.scss';

.Loader {
    display: flex;
    justify-content: center;
    &::after {
      animation: spin 2s linear infinite;
      align-self: center;
      box-sizing: border-box;
      border: 8px solid rgba(255, 255, 255, 0.5);
      border-top-color: white;
      border-radius: 50%;
      content: "";
      display: flex;
      width: 28px;
      height: 28px;
    }
    &.dark {
      &::after {
        border-color: $primary_light;
        border-top-color:  $primary;
      }
    }
    &.big {
      &::after {
        width: 48px;
        height: 48px;
        border-width: 12px;
      }
    }
  }
  
  .loader-wrapper {
    height: 100%;
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
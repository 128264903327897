$primary: #00529b;
$secondary: #e73c37;
// Buttons
$primary_disabled: #074275;
$primary_hover: #1774c5;
//borders
$primary_faded: #5199d8;    //spiele section - selected game
$secondary_faded: rgb(236, 159, 159);
//tables
$primary_light: #b3cce2; // on hovering rows
$secondary_light: #fbe9ea;